<template>
	<div class="h-100">
		<template v-if="isLoading">
			<BaseLoading />
		</template>
		<div
			class="h-100 h-100 d-flex align-items-center justify-content-center flex-column card-panel bg-white shadow-sm shadow-hover"
		>
			<div class="text-center shadow shadow-sm p-4 mx-4 bg-success">
				<i class="fa fa-check-circle fa-3x fa-fw text-success m-2"></i>
				<span class="text-justify text-success mt-0 mb-0">
					<h2 class="text-center">Conferência finalizada!</h2>
				</span>
				<p>
					Acesse a rotina "Recebimento Mercadorias" para concluir o processo e finalizar o recebimento
					<strong>{{ idRecebimento }}</strong
					>.</p
				>
			</div>
			<div class="mt-4">
				<button
					id="lmxta-recebimento-btn-retomarconferencia"
					type="button"
					class="btn btn-primary rounded"
					tabindex="8"
					@click="retomarConferenciaOnline()"
				>
					<span class="text-center">Retomar Conferência</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import swal from '@/common/alerts';
	import loading from '@/common/loading';
	import { mapGetters } from 'vuex';
	import VTooltip from 'v-tooltip';
	import Vue from 'vue';

	Vue.use(VTooltip);

	export default {
		data() {
			return {
				idRecebimento: null,
				msgRetornoRecebimento: '',
				conferenciaOnlineFinalizada: false,
			};
		},
		created() {
			var self = this;
			self.idRecebimento = self.$route.params.idRecebimento;
		},
		beforeMount() {},
		computed: {
			...mapGetters({
				listaItensConferidos: 'recebimento/listaItensConferidos',
			}),
			isLoading() {
				return this.$store.state.uiState.isLoading;
			},
			isMobile() {
				if (navigator.userAgent.indexOf('Mobile') > 0) {
					return true;
				} else {
					return false;
				}
			},
			bootstrapConflict() {
				return {
					template:
						'<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
					arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
					innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
				};
			},
		},
		methods: {
			apresentarTooltip(mensagem) {
				return { content: mensagem, ...this.bootstrapConflict };
			},
			retomarConferenciaOnline() {
				var self = this;
				if (!this.isLoading) {
					loading.exibir();
					return self.$store
						.dispatch('recebimento/retomarConferenciaOnline', self.idRecebimento)
						.then(() => {
							self.redirecionarPagina();
						})
						.catch(function (erro) {
							let mensagemErro = '';

							if (erro.response) {
								if (erro.response.status == 400) mensagemErro = erro.data;
								else mensagemErro = erro.message;
							} else mensagemErro = erro;

							self.msgRetornoRecebimento = mensagemErro;
							swal.exibirMensagemErro(mensagemErro);
						})
						.finally(function () {
							loading.ocultar();
						});
				} else {
					swal.exibirMensagemErro('Ocorreu um problema.');
				}
			},
			redirecionarPagina() {
				this.$router.push({
					name: 'ConferenciaOnlineRecebimento',
					query: { idRecebimento: this.idRecebimento },
				});
			},
		},
	};
</script>
